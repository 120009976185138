<script>
export default {
    name: "",
    data() {
        return {
            modal1: false,
            vertical: '',
        }
    },
    mounted() {
        this.initLanguage()
    },
    methods: {
        language() {
            this.modal1 = true
        },
        changelanguage: function (name) {
            let tempname = name
            if (name == 'en-US-cam') {
                tempname = 'en-US'
            }

            console.log("change language: " + name);
            this.$store.commit("setlang", tempname);
            this.$i18n.locale = tempname;
            this.country_en = tempname


            if (this.$store.getters.lang == 'zh-CN') {
                this.currentLang = this.$t('uc.regist.china')
            } else if (this.$store.getters.lang == 'zh-HK') {
                this.currentLang = this.$t('uc.regist.hk')
            } else if (this.$store.getters.lang == 'en-US') {
                this.currentLang = this.$t('uc.regist.uk')
            } else if (this.$store.getters.lang == 'ca-CB') {
                this.currentLang = this.$t('uc.regist.cambodia')
            } else if (this.$store.getters.lang == 'vi-VN') {
                this.currentLang = this.$t('uc.regist.vietnam')
            } else if (this.$store.getters.lang == 'vi-VN') {
                this.currentLang = this.$t('uc.regist.vietnam')
            } else if (this.$store.getters.lang == 'vi-VN') {
                this.currentLang = this.$t('uc.regist.vietnam')
            }

            this.modal1 = false
            if (this.$route.name === 'About') {
                window.location.reload()
            }
        },
        cancel() {
            this.modal1 = false
        },
        initLanguage() {
            if (this.$store.getters.lang == 'zh-CN') {
                this.currentLang = this.$t('uc.regist.china')
            } else if (this.$store.getters.lang == 'zh-HK') {
                this.currentLang = this.$t('uc.regist.hk')
            } else if (this.$store.getters.lang == 'en-US') {
                this.currentLang = this.$t('uc.regist.uk')
            } else if (this.$store.getters.lang == 'ca-CB') {
                this.currentLang = this.$t('uc.regist.cambodia')
            } else if (this.$store.getters.lang == 'vi-VN') {
                this.currentLang = this.$t('uc.regist.vietnam')
            } else if (this.$store.getters.lang == 'th-TH') {
                this.currentLang = this.$t('uc.regist.thailand')
            } else if (this.$store.getters.lang == 'ja-JP') {
                this.currentLang = this.$t('uc.regist.japan')
            } else if (this.$store.getters.lang == 'ma-MA') {
                this.currentLang = this.$t('uc.regist.malaysia')
            } else if (this.$store.getters.lang == 'in-IN') {
                this.currentLang = this.$t('uc.regist.india')
            } else if (this.$store.getters.lang == 'ko-KR') {
                this.currentLang = this.$t('uc.regist.korea')
            }
            this.vertical = this.$store.getters.lang
        }
    }
}
</script>

<template>
    <div>
        <Modal
            v-model="modal1"
            :title="$t('uc.finance.xx81')"
            @on-ok="changelanguage(vertical)"
            @on-cancel="cancel">
            <RadioGroup v-model="vertical" vertical>
                <Radio label="zh-CN" style="display: flex;align-items: center;">
                    <img src="../assets/logo/china.png" width="24" height="24">
                    <span class="txt">中文</span>
                </Radio>
                <Radio label="th-TH" style="display: flex;align-items: center;">
                    <img src="../assets/logo/thai.png" width="24" height="24">
                    <span class="txt">{{ $t('uc.regist.thailand') }}</span>
                </Radio>
                <Radio label="vi-VN" style="display: flex;align-items: center;">
                    <img src="../assets/logo/vietnam.png" width="24" height="24">
                    <span class="txt">{{ $t('uc.regist.vietnam') }}</span>
                </Radio>
                <!--                <Radio label="ma-MA" style="display: flex;align-items: center;">-->
                <!--                    <img src="../assets/logo/mala.png" width="24" height="24">-->

                <!--                    <span class="txt">{{ $t('uc.regist.malaysia') }}</span>-->
                <!--                </Radio>-->
                <!--                <Radio label="ca-CB" style="display: flex;align-items: center;">-->
                <!--                    <img src="../assets/logo/cambodia.png" width="24" height="24">-->

                <!--                    <span class="txt">{{ $t('uc.regist.cambodia') }}</span>-->
                <!--                </Radio>-->
                <Radio label="in-IN" style="display: flex;align-items: center;">
                    <img src="../assets/logo/indiaN.png" width="24" height="24">

                    <span class="txt">{{ $t('uc.regist.india') }}</span>
                </Radio>
                <Radio label="en-US" style="display: flex;align-items: center;">
                    <img src="../assets/logo/usa.png" width="24" height="24">

                    <span class="txt">{{ $t('uc.regist.uk') }}</span>
                </Radio>
                <Radio label="ja-JP" style="display: flex;align-items: center;">
                    <img src="../assets/logo/japan.png" width="24" height="24">

                    <span class="txt">{{ $t('uc.regist.japan') }}</span>
                </Radio>
                <Radio label="ko-KR" style="display: flex;align-items: center;">
                    <img src="../assets/logo/korea.png" width="24" height="24">
                    <span class="txt">{{ $t('uc.regist.korea') }}</span>
                </Radio>
            </RadioGroup>
        </Modal>
    </div>
</template>
<style scoped lang="scss">
.txt {
    padding-left: 6px;
    font-size: 16px;
}

/deep/ .ivu-radio-wrapper {
    min-height: 40px;
}
</style>
