<script>
import {getToken} from "@/util/tools";
import {numberToCurrencyNo} from "@/config";
import LanguagePopup from "@/components/LanguagePopup.vue";

export default {
    name: "",
    components: {LanguagePopup},
    data() {
        return {
            cityList: [],
            currencyCoin: '', //当前币种图标
            cType: '',
            ifLogin: false,
            userInfo: null,
            country_en: "",
        }
    },
    created() {
        this.initToken()
        this.getCurrency()
    },
    computed: {
        member: function () {
            return this.$store.getters.member || localStorage.getItem("MEMBER");
        },
        isLoginT: function () {
            return this.$store.getters.isLogin;
        },
    },
    watch: {
        isLoginT(newVal, old) {
            if (newVal && newVal !== 'null') {
                this.ifLogin = true
                this.getCurrency()
            } else {
                this.ifLogin = false
            }
        },
        cType(newVal, old) {
            console.log( newVal,this.cType )
            let c = this.cityList.filter(item => item.ccy === this.cType)[0]
            if(c) {  //切换币种以后才会有值  不然this.cityList为空
                this.currencyCoin = this.cityList.filter(item => item.ccy === this.cType)[0]
            }
        }

    },
    methods: {
        shouLanguage(){
            this.$refs.language.modal1 = true
        },
        goRoute(path) {
            if (path === this.$route.name) return
            if (path === 'my') this.$router.push({path: path})
            if (this.userInfo && !this.userInfo.jbPassword) {
                // console.log( this.userInfo , this.userInfo.jbPassword )
                if (path === 'Recharge') this.$parent.fundPwdPopup = true
                if (path === '/') this.$router.push({path: path})
            } else {
                this.$router.push({path: path})
            }
        },
        // init(){
        //     if(this.member){
        //         console.log( this.member )
        //         this.userInfo = JSON.parse(JSON.stringify(this.member))
        //     }
        //     if(localStorage.getItem('MEMBER') && localStorage.getItem('MEMBER') !== 'null'){
        //         console.log( localStorage.getItem('MEMBER') )
        //         this.userInfo = JSON.parse(localStorage.getItem('MEMBER'))
        //     }
        // },
        gotoLogin() {
            this.$store.commit("showLoginFn", true)
            // this.$router.push("/login");
        },
        gotoReg() {
            this.$store.commit("showRegisterFn", true)
            // this.$router.push("/MobileRegister");
        },
        initToken() {
            const t = getToken()
            const m = this.member && this.member !== 'null'
            if (t || m) {
                this.ifLogin = true
            } else {
                this.ifLogin = false
            }
        },
        getCurrency() {
            if (this.member && this.member !== 'null') {
                let memberItem
                // console.log( this.member )
                memberItem = typeof this.member === "string" ? JSON.parse(this.member) : this.member
                this.userInfo = memberItem
                this.cType = memberItem.remark
                this.$http.post(this.host + this.api.uc.personalWallet).then(response => {
                    let resp = response.body;
                    this.cityList = []
                    if (resp.code == "0000") {
                        resp.data.forEach(item => {
                            let path
                            if (item.ccy === 'USDT') {
                                path = require('@/assets/logo/usdt.png');
                            } else if (item.ccy === 'USD') {
                                path = require('@/assets/logo/usa.png')
                            } else if (item.ccy === 'VND') {
                                path = require('@/assets/logo/vietnam.png')
                            } else if (item.ccy === 'CNY') {
                                path = require('@/assets/logo/china.png')
                            } else if (item.ccy === 'IDR') {
                                path = require('@/assets/logo/indiaN.png')
                            } else if (item.ccy === 'JPY') {
                                path = require('@/assets/logo/japan.png')
                            } else if (item.ccy === 'KRW') {
                                path = require('@/assets/logo/korea.png')
                            } else if (item.ccy === 'THB') {
                                path = require('@/assets/logo/thai.png')
                            }
                            this.cityList.push({
                                amount: item.amount,
                                ccy: item.ccy,
                                id: item.id,
                                uid: item.uid,
                                userNo: item.userNo,
                                value: item.ccy,
                                label: item.amount,
                                path
                            })
                        });
                        let c = this.cityList.filter(item => item.ccy === this.cType)[0]
                        if(c) {
                            this.currencyCoin = this.cityList.filter(item => item.ccy === this.cType)[0]
                        }
                    } else {
                        this.$Notice.error({
                            // title: this.$t("common.tip"),
                            title: 'err',
                            desc: resp.mesg
                        });
                        console.log(resp.code)
                        if (resp.code === '401') {
                            localStorage.clear()
                            sessionStorage.clear()
                            this.$router.push({path: '/'})
                            window.location.reload()
                        }
                    }
                });
            }

        },
        changeType(val) {
            let mber = this.member
            if (typeof mber === 'object') mber = JSON.stringify(mber)
            let params = {
                remark: val,
                // userNo: JSON.parse(mber).userNo,
            }
            this.$http.post(this.host + this.api.uc.updatePersonalInfo, params)
                .then(response => {
                    const resp = response.body;
                    if (resp.code == '0000') {
                        // this.member.huioneName = this.huioneName;
                        // this.$store.commit("setMember", this.member);
                        // this.$Message.success(this.$t("uc.finance.xx5"));
                        this.getMemberInfo()
                    }
                })
        },
        getMemberInfo() {
            //获取个人安全信息
            var self = this;
            this.$http.post(self.host + this.api.uc.memberInfo).then(response => {
                var resp = response.body;
                if (resp.code == "0000") {
                    self.userInfo = resp.data
                    localStorage.setItem("MEMBER", JSON.stringify(null));
                    self.$store.commit("setMember", resp.data);
                    self.isagent = resp.data.userType
                    self.nicknamexx = self.$store.getters.member.nickname
                    self.userNoxx = self.$store.getters.member.userNo
                    // self.moneyList.forEach(element => {
                    //     if (element.ccy == resp.data.remark) {
                    //         self.moneybab = element
                    //     }
                    // });
                    // self.moneyamout = numberToCurrencyNo(self.moneybab.amount)
                } else {
                    self.$Message.error(resp.mesg);
                }
            });
        },
    }
}
</script>

<template>
    <div class="header-box">
        <img src="../assets/logo/logojb1.png" style="width: 100px;" alt="" @click="goRoute('/')">
        <!--币种-->
        <Select @on-change="changeType" v-if="ifLogin" v-model="cType" prefix="coin" style="width:160px;float: left">
<!--            <Avatar :src="currencyCoin.path"-->
<!--                    style="width: 20px;height: 20px;vertical-align: text-top" slot="prefix" size="small"/>-->
            <Option v-for="item in cityList" :value="item.value" :key="item.value" :label="item.label">
                <img class="c-coin" :src="item.path" alt="">
                <span class="ccy"> {{ item.ccy }}</span>
                <span class="money"> {{ item.label }}</span>
            </Option>
            <div slot="prefix" style="color: #fff;font-weight: bold;padding: 0 2px">{{ cType }}</div>
        </Select>
        <div class="l-r-btn" v-else>
            <div class="btn-login btn" @click="gotoLogin">{{ $t('uc.login.login') }}</div>
            <div class="btn-reg btn" @click="gotoReg">{{ $t('common.register') }}</div>
        </div>
        <div v-if="ifLogin" class="wallet" @click="goRoute('Recharge')">
<!--            <Icon style="font-size: 24px;color: #fff" type="md-map"/>-->
            <img style="width: 20px" src="../assets/common/wallet.png" alt="">
        </div>
<!--        <div v-if="ifLogin" @click="goRoute('my')">-->
<!--            <Icon style="font-size: 24px;color: #fff" type="ios-person"/>-->
<!--        </div>-->
        <div style="width: 24px;margin: 0 0 0 6px" @click="shouLanguage">
            <img class="icon" style="width: 24px" src="@/assets/logo/lang.png" alt="Ting SVG"/>
        </div>
        <!--        <div v-if="ifLogin">-->
        <!--            <Icon style="font-size: 22px;color: #fff" type="ios-notifications"/>-->
        <!--        </div>-->
        <LanguagePopup ref="language"></LanguagePopup>
    </div>
</template>

<style scoped lang="scss">
.header-box {
    position: fixed;
    top: 0;
    height: 60px;
    width: 100%;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    display: flex;
    background: #1A2C38;
    box-shadow: #0003 0 4px 6px -1px, #0000001f 0 2px 4px -1px;
    z-index: 1000;
}

.l-r-btn {
    width: 70%;
    display: flex;
    justify-content: end;

    .btn-login {
        margin-right: 10px;
        padding: 8px 20px;
        border-radius: 4px;
    }

    .btn {
        color: #fff;
        font-weight: bold;
        font-size: 16px;
    }

    .btn-reg {
        background-color: #1375E1;
        padding: 8px 20px;
        border-radius: 4px;
        margin-right: 10px;
    }
}

.wallet {
    padding: 10px 14px;
    background-color: #1375E1;
    border-radius: 4px;
}

/deep/ .ivu-select {
    height: 80%;

    .ivu-select-selection {
        height: 100%;
        border: none;
        border-radius: 0;

        & > div {
            height: 100%;
            background-color: #0E212E;
            display: flex;

            justify-content: center;
            align-items: center;

            .ivu-select-selected-value {
                height: 100%;
                color: #fff;
                font-weight: bold;
                display: flex;
                align-items: center;
                line-height: 48px;
            }
        }
    }
}
/deep/ .ivu-select-dropdown{
    max-height: 400px;
    left: 3%!important;
    width: 94%!important;
    padding: 10px 0!important;
    .ivu-select-item{
        width: 96%;
        border-radius: 6px;
        margin: auto;
        padding: 0 10px;
        height: 48px;
        line-height: 48px;
    }
}
.ccy,.c-coin{
    float: left;
    font-size: 16px;
    color: #000;
    display: flex;
    align-items: center;
}

.c-coin{
    margin-top: 14px;
    width: 20px;
}

.ccy{
    padding-left: 10px;
    font-size: 16px;
}

.money{
    font-size: 18px;
    float: right;
    color: #000;
}
/deep/ .ivu-select-item-focus{ //选中背景色
    background-color: #1D2B38;
    .ccy,.money{
        display: flex;
        align-items: center;
        color: #fff;
    }
}


</style>
