<template>
   <Tab-bar>
      <Tab-bar-item path="/browse" activeColor="#999999">
          <img slot="item-icon" src="../../assets/logo/asset_0.svg" alt="">
          <img slot="item-icon-active" src="../../assets/logo/asset_00.svg" alt="">
        <div slot="item-text">{{$t('new.Browse')}}</div>
      </Tab-bar-item>
      <Tab-bar-item path="/" activeColor="#999999">
          <img slot="item-icon" src="../../assets/logo/asset_1.svg" alt="">
          <img slot="item-icon-active" src="../../assets/logo/asset_11.svg"  alt="" >
        <div slot="item-text" >{{$t('new.Casino')}}</div>
      </Tab-bar-item>
<!--      <Tab-bar-item path="/about1" activeColor="#999999">-->
<!--          <img slot="item-icon"  src="../../assets/logo/asset_2.svg" alt="">-->
<!--          <img slot="item-icon-active" src="../../assets/logo/asset_22.svg" alt="">-->
<!--       <div slot="item-text" >{{$t('new.Bets')}}</div>-->
<!--      </Tab-bar-item>-->
      <Tab-bar-item path="/sports" activeColor="#999999">
        <img slot="item-icon" src="../../assets/logo/asset_3.svg" alt="">
        <img slot="item-icon-active" style="" src="../../assets/logo/asset_33.svg" alt="">
        <div slot="item-text">{{$t('new.Sports')}}</div>
      </Tab-bar-item>
      <Tab-bar-item path="/active" activeColor="#999999">
        <img slot="item-icon" src="../../assets/logo/gift.png" alt="">
        <img slot="item-icon-active" style="" src="../../assets/logo/gift_active.png" alt="">
        <div slot="item-text">{{$t('new.active')}}</div>
      </Tab-bar-item>
      <Tab-bar-item path="/my" activeColor="#999999">
          <img slot="item-icon" src="../../assets/logo/my.png" alt="">
          <img slot="item-icon-active" src="../../assets/logo/my_active.png" alt="">
        <div slot="item-text">{{$t('new.my')}}</div>
      </Tab-bar-item>

    </Tab-bar>
</template>

<script>

import TabBar from './TabBar'
import TabBarItem from './TabBarItem'

export default {
  components:{
    TabBar,
    TabBarItem
  }
}
</script>

<style>

</style>
